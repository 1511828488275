import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M686.545 954.182H324.073A122.298 122.298 0 0 1 201.89 832V196.538A122.298 122.298 0 0 1 324.073 74.356h362.472a122.298 122.298 0 0 1 122.182 122.182V832a122.298 122.298 0 0 1-122.182 122.182zM324.073 132.538a64.116 64.116 0 0 0-64 64V832a64.116 64.116 0 0 0 64 64h362.472a64.116 64.116 0 0 0 64-64V196.538a64.116 64.116 0 0 0-64-64z" />
      <path d="M569.135 232.727h-128a29.09 29.09 0 0 1 0-58.182h128a29.09 29.09 0 1 1 0 58.182zM451.84 809.658a53.295 53.295 0 1 0 106.59 0 53.295 53.295 0 1 0-106.59 0Z" />
    </svg>
  )
}

export default SVG
