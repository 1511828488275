import { memo, type SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M538.667 872.076s-.61 0 0 0c-20.572 0-38.705-13.562-44.8-33.371L349.41 354.743 280.99 562.59c-6.247 19.2-24.076 32-44.342 32H91.733c-12.343 0-24.228-4.876-32.914-13.714-8.838-8.838-13.714-20.571-13.714-32.914.305-25.6 21.028-46.476 46.628-46.629H202.82l104.838-317.257c6.248-19.2 24.99-31.543 45.257-32 20.267.457 38.248 14.02 44.038 33.372l142.629 478.628 108.038-353.371c5.79-18.59 22.705-31.695 42.21-32.915 19.657-.457 37.79 10.21 45.257 28.496l81.676 195.504h115.505c25.447 0 46.628 21.029 46.628 46.629s-20.571 46.629-46.628 46.629h-146.59c-18.896 0-35.658-11.124-43.125-28.496L698.21 461.714 582.857 839.62c-6.247 19.048-24.228 32.152-44.19 32.457z" />
    </svg>
  )
}

export default memo(SVG)
