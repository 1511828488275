import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M940.8 633.6H795.2c0-46.4-8-89.6-17.6-128l120-124.8c17.6-17.6 17.6-43.2 0-59.2-17.6-17.6-43.2-17.6-59.2 0L744 420.8c-20.8-38.4-46.4-73.6-76.8-99.2 12.8-8 25.6-12.8 33.6-25.6 20.8-25.6 33.6-56 30.4-89.6C718.4 89.6 627.2 0 512 0 400 0 305.6 86.4 292.8 201.6c-4.8 33.6 8 68.8 30.4 89.6 8 12.8 20.8 20.8 33.6 25.6-30.4 25.6-56 56-76.8 94.4l-89.6-94.4c-17.6-17.6-43.2-17.6-59.2 0-17.6 17.6-17.6 43.2 0 59.2l115.2 120c-12.8 43.2-20.8 86.4-20.8 132.8H83.2C57.6 628.8 40 646.4 40 672s17.6 43.2 43.2 43.2h145.6c4.8 25.6 8 46.4 17.6 73.6l-120 128c-17.6 17.6-17.6 43.2 0 59.2 8 8 17.6 12.8 30.4 12.8 12.8 0 20.8-4.8 30.4-12.8l99.2-99.2c49.6 88 131.2 147.2 220.8 147.2s176-56 227.2-145.6l99.2 102.4c8 8 20.8 12.8 30.4 12.8 12.8 0 20.8-4.8 30.4-12.8 17.6-17.6 17.6-43.2 0-59.2l-124.8-128c8-20.8 12.8-43.2 17.6-68.8h153.6c25.6 0 43.2-17.6 43.2-43.2s-17.6-48-43.2-48zM512 86.4c68.8 0 124.8 56 132.8 124.8 0 8 0 17.6-8 25.6s-17.6 12.8-20.8 12.8H409.6c-8 0-17.6-4.8-20.8-8-4.8-8-8-17.6-8-25.6C384 140.8 443.2 86.4 512 86.4zm-4.8 848c-112 0-201.6-132.8-201.6-291.2S396.8 352 507.2 352s201.6 132.8 201.6 291.2-89.6 291.2-201.6 291.2z" />
    </svg>
  )
}

export default SVG
