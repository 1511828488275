import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M553.3 844.3h-83c-22.9 0-41.5 18.6-41.5 41.5s18.6 41.5 41.5 41.5h83c22.9 0 41.5-18.6 41.5-41.5s-18.6-41.5-41.5-41.5zm83-140.2H387.4c-22.9 0-41.5 18.6-41.5 41.5s18.6 41.5 41.5 41.5h248.9c22.9 0 41.5-18.6 41.5-41.5s-18.6-41.5-41.5-41.5zM730 196.2c-.2-.3-.5-.6-.7-.8l-.7-.7c-.9-1-1.8-2-2.8-3.1-.6-.6-1.1-1.2-1.6-1.8l-1.2-1.2c-.6-.7-1.3-1.3-1.9-2l-1-1c-1-1-1.9-2-2.9-3l-.1-.1c-.8-.8-1.7-1.6-2.5-2.5l-1.3-1.3-1.2-1.2c-.6-.6-1.2-1.2-1.8-1.7l-.7-.6c-.8-.7-1.5-1.4-2.3-2.1l-.2-.2c-24.1-22-51.8-39.7-81.8-52.4l-.5-.2-1.8-.7-1.8-.7-.5-.2c-30.2-12.2-62.3-19.2-94.9-20.8h-.3c-1 0-2-.1-3.1-.1h-1c-.8 0-1.7-.1-2.5-.1h-14.4c-1 0-2 .1-2.9.1h-.2c-33.8 1.2-67.1 8.3-98.4 21l-.8.3-1.9.8c-33.3 13.8-63.7 33.8-89.6 58.8l-.5.5c-.6.5-1.1 1.1-1.6 1.6l-.4.4-1.7 1.7-1.4 1.4-.5.5c-.5.6-1.1 1.1-1.6 1.7l-.3.3c-25.3 26.2-45.4 56.9-59.3 90.6v.1c-.3.7-.6 1.5-.9 2.2l-.1.2c-42 104.7-20.6 228.9 64.2 313.7l.3.3 14.4 14.4c16.1 16.3 42.4 16.4 58.7.3 16.3-16.1 16.4-42.4.3-58.7l-.3-.3-11.7-11.7-2.9-2.9c-67.8-67.8-80.4-173.2-30.3-255 6.4-10.4 13.7-20.3 21.8-29.4l.1-.1c.6-.7 1.2-1.4 1.8-2l.4-.5c.5-.6 1-1.1 1.5-1.7l.6-.6c.5-.5 1-1 1.5-1.6l.5-.6c.7-.7 1.4-1.4 2-2.1l.3-.3 1.5-1.5 1-1 .8-.8c.4-.4.9-.8 1.3-1.2l.5-.5c.6-.5 1.1-1 1.6-1.5l.2-.2c17.3-15.7 37.1-28.4 58.6-37.5l1.3-.5 1.3-.5c21.7-8.8 44.6-13.8 68-14.9h.3c.7 0 1.5-.1 2.2-.1h.7l1.8-.1H517l1.8.1h.7l2.2.1h.3c23.3 1.1 46.3 6.1 68 14.9l1.3.6 1.3.5c21.5 9.1 41.4 21.8 58.6 37.5l.2.2c.6.5 1.1 1 1.6 1.5l.5.5c.4.4.9.8 1.3 1.2l.8.8 1 1 1.5 1.5.3.3 3.1 3.1.3.3c1 1 1.9 2 2.8 3l.5.5c.9 1 1.8 1.9 2.6 2.9l.6.7c.8.9 1.6 1.9 2.4 2.8 73.6 88 62 218.9-25.9 292.6-6.5 5.4-13.3 10.4-20.4 15H499c-22.9 0-41.5 18.6-41.5 41.5s18.6 41.5 41.5 41.5h137.3c9.1 0 16.2-2.9 23.4-7.2 85.3-50.6 142.6-143.6 142.6-250 0-70.9-25.7-139.1-72.3-192z" />
    </svg>
  )
}

export default SVG
