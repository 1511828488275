import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M510.546 5.042c102.406 0 195.214 38.076 262.48 99.59 67.345 61.595 108.99 146.708 108.99 240.707 0 25.066-2.954 49.418-8.328 73.136-5.335 23.52-13.029 46.206-22.569 68.02-28.555 65.56-146.866 200.013-211.356 273.308-7.237 8.25-13.604 14.792-18.86 20.068v41.167H408.062v-44.302c-17.174-18.877-36.29-38.55-55.962-58.818-99.312-102.327-213.003-219.487-213.003-372.58 0-93.999 41.665-179.112 109.01-240.708C315.351 43.118 408.14 5.042 510.546 5.042zm85.133 881.836-17.47 133.898H456.724l-19.057-133.898h158.011zM510.348 758.69h64.094C635.6 512.116 688.866 281.801 623.78 68.224c-35.24-12.018-73.375-18.562-113.234-18.562-1.486 0-2.973 0-4.482.04-25.562 235.232 1.567 472.607 4.284 708.988zM312.08 111.654c-120.096 146.826-112.798 310.43-8.19 486.805 26.91 31.255 55.764 60.96 83.428 89.477 18.125 18.68 35.774 36.845 52.294 54.891-61.258-205.01-171.16-477.366-28.776-678.886-36.131 10.628-69.447 26.929-98.756 47.713zm433.918 30.658c62.27 141.195 30.082 325.779-44.6 475.145 46.702-56.239 90.725-113.63 105.579-147.66 8.566-19.631 15.428-39.82 20.127-60.523 4.661-20.545 7.2-41.843 7.2-63.935 0-78.61-33.553-150.079-88.306-203.027z" />
    </svg>
  )
}

export default SVG
