import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="prefix__icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <defs>
        <style />
      </defs>
      <path d="M286.72 245.76a40.96 40.96 0 00-81.92 0v204.8a40.96 40.96 0 01-81.92 0v-204.8a122.88 122.88 0 01122.88-122.88H819.2a81.92 81.92 0 0181.92 81.92V840a60.847 60.847 0 01-16.533 41.707 61.653 61.653 0 01-86.827 2.986L635.627 734.848a61.44 61.44 0 00-83.414 0L390.08 884.672a61.653 61.653 0 01-86.827-2.987A60.928 60.928 0 01286.72 840V245.76zm273.493 105.472a50.432 50.432 0 01-45.141 45.141l-59.904 5.995a27.861 27.861 0 000 55.424l59.904 5.995a50.432 50.432 0 0145.141 45.141l5.995 59.904a27.861 27.861 0 0055.424 0l5.995-59.904a50.432 50.432 0 0145.141-45.141l59.904-5.995a27.861 27.861 0 000-55.424l-59.904-5.995a50.432 50.432 0 01-45.141-45.141l-5.995-59.904a27.861 27.861 0 00-55.424 0l-5.995 59.904z" />
    </svg>
  )
}

export default SVG
